import {Box, useMediaQuery, useTheme} from "@mui/material";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Navbar from "./sections/Navbar";
import Header from "./sections/Header";
import About from "./sections/About";
import Mission from "./sections/Mission";
import Vision from "./sections/Vision";
import Services from "./sections/Services";
import ServicesCard from "./sections/servicesCard";
import Solutions from "./sections/Solutions";
import Team from "./sections/Team";
import Footer from "./sections/Footer";
import SMNavbar from "../SmallScreenView/sections/SMNavbar";
import SMHeader from "../SmallScreenView/sections/SMHeader";
import SMAbout from "../SmallScreenView/sections/SMAbout";
import SMMission from "../SmallScreenView/sections/SMMission";
import SMVision from "../SmallScreenView/sections/SMVision";
import SMServices from "../SmallScreenView/sections/SMServices";
import SMServicesCard from "../SmallScreenView/sections/SMServicesCard";
import SMSolutions from "../SmallScreenView/sections/SMSolutions";
import SMTeam from "../SmallScreenView/sections/SMTeam";
import React from "react";

const HomePage = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const whatsappBtn = {
        color: 'white',
        height: '60px',
        width: '60px',
    };

    const whatsappBtnOuterBox = {
        position: 'fixed',
        zIndex: 50,
        height: '75px',
        width: '75px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#00DE6B',
        bottom: {xs: 35, sm: 75},
        right: {xs: 35, sm: 75},
        borderRadius: '50%',
        border: '1px solid transparent',
        transition: '0.5s',
        transform: 'scale(0.8)',
        '&:hover': {
            boxShadow: '0px 0px 8px rgba(0,222,107,0.75)',
            border: '1px solid #bfbfbf',
            backgroundColor: '#009f50',
            transform: 'scale(1.1)',
        }
    }

    return(
        !isSmallScreen ? (
            <>
                <Box component={'a'} sx={whatsappBtnOuterBox} href={'https://wa.me/971554762630'}><WhatsApp sx={whatsappBtn}/></Box>
                <Navbar></Navbar>
                <Header></Header>
                <About></About>
                <Mission></Mission>
                <Vision></Vision>
                <Services></Services>
                <ServicesCard></ServicesCard>
                <Solutions></Solutions>
                <Team></Team>
                <Footer></Footer>
            </>
        ) : (
            <>
                <Box component={'a'} sx={{...whatsappBtnOuterBox, height: '55px', width: '55px'}} href={'https://wa.me/971554762630'}><WhatsApp sx={{...whatsappBtn, height: '45px', width: '45px'}}/></Box>
                <SMNavbar/>
                <SMHeader/>
                <SMAbout/>
                <SMMission/>
                <SMVision/>
                <SMServices/>
                <SMServicesCard/>
                <SMSolutions/>
                <SMTeam/>
                <Footer></Footer>
            </>
        )
    )
}

export default HomePage;