import React from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";
import {LocalPhone, Email, Language, Facebook, Twitter, LinkedIn, Instagram} from "@mui/icons-material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
    arrowIconStyle,
    gridStyles,
    iconStylesLeft,
    logoStyle,
    logoTitleStyle,
    mainBoxStyles, tabContactText, tabText,
    titleStyles
} from "../../stylesheets/footerStyles";
import logo from '../../assets/imgs/so_logo.jpeg';

const Footer = () => {
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const leftGridData = [
        {
            icon: <Email sx={iconStylesLeft}></Email>,
            title: 'info@syedsaqlainsecurityservices.com'
        },

        {
            icon: <LocalPhone sx={iconStylesLeft}></LocalPhone>,
            title: '052-6524544'
        },
        {
            icon: <LocalPhone sx={iconStylesLeft}></LocalPhone>,
            title: '055-4762630'
        },


        {
            icon: <Language sx={iconStylesLeft}></Language>,
            title: 'www.syedsaqlainsecurityservices.com'
        }
    ]

    const centerLeftData = [
        {
            title: 'Surveillance'
        },

        {
            title: 'Cyber Security'
        },

        {
            title: 'Physical Security'
        },

        {
            title: 'Operational Security'
        },

        {
            title: 'Information Security'
        },
    ]

    const centerRightData = [
        {
            title: "Home",
            data: "home",
        },

        {
            title: "About",
            data: "about",
        },

        {
            title: "Services",
            data: "services",
        },

        {
            title: "Solutions",
            data: "solutions",
        },

        {
            title: "Team",
            data: "team",
        },
    ]

    const handleContactClick = (index) => {
        if(index === 0) {
            const email = 'info@syedsaqlainsecurityservices.com';
            const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;

            const newWindow = window.open(gmailUrl, '_blank');

            if (newWindow) {
                newWindow.focus();
            } else {
                alert('Please Allow Popups to Open Gmail.');
            }
        } else if (index === 1){
            const phoneNumber = '+971526524544';
            window.location.href = `tel:${phoneNumber}`;
        }
    }

    return(<Box>
        <Box sx={mainBoxStyles}>
            <Grid container paddingX={'2rem'}>
                <Grid item xs={12} sm={6} md={3} sx={gridStyles}>
                    <img style={logoStyle} src={logo} alt={'logo'}/>
                    <Typography sx={logoTitleStyle} variant={'h4'}>Syed Saqlain Security Services LLC</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <Typography sx={titleStyles} variant={'h4'}>Our Services</Typography>
                    {centerLeftData.map((Item) => (
                        <Stack direction={'row'} alignItems={'center'}>
                            <ArrowForwardIosIcon sx={arrowIconStyle}/>
                            <Typography variant={'h5'} sx={tabText}>{Item.title}</Typography>
                        </Stack>
                    ))}
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <Typography sx={titleStyles} variant={'h4'}>Quick Links</Typography>
                        {centerRightData.map((Item) =>(
                        <Stack direction={'row'} alignItems={'center'} sx={{cursor: 'pointer'}} onClick={()=> scrollToSection(Item.data)}>
                            <ArrowForwardIosIcon sx={arrowIconStyle}/>
                            <Typography variant={'h5'} sx={tabText}>{Item.title}</Typography>
                        </Stack>))}
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <Typography sx={titleStyles} variant={'h4'}>Contact Us</Typography>
                    {leftGridData.map((Item, index) =>(<Stack direction={'row'} sx={{columnGap: '1rem', alignItems: 'center', marginX: 'auto'}}>
                        {Item.icon}
                        <Typography variant={'h5'} sx={{...tabContactText, '&:hover' : {color: index === 0 || index === 1 || index === 2 ? 'red' : 'white', cursor: (index === 0 || index === 1 || index === 2) && 'pointer'}}} onClick={()=> handleContactClick(index)}>{Item.title}</Typography>
                    </Stack>))}
                    <Stack direction={'row'} sx={{alignItems: 'center', justifyContent: 'center', marginTop: '1rem'}}>
                        <Facebook sx={{height: '35px', width: '35px', color: 'var(--red-theme-color)'}}></Facebook>
                        <Twitter sx={{height: '35px', width: '35px', color: 'var(--red-theme-color)'}}></Twitter>
                        <LinkedIn sx={{height: '35px', width: '35px', color: 'var(--red-theme-color)'}}></LinkedIn>
                        <Instagram sx={{height: '35px', width: '35px', color: 'var(--red-theme-color)'}}></Instagram>
                    </Stack>
                </Grid>
            </Grid>
            <Typography sx={{color: 'var(--red-theme-color)', textAlign: 'center', marginTop: '1rem'}} variant={'h6'}>© 2023-2024 Security Organization. All rights reserved.</Typography>
        </Box>
    </Box>)
}

export default Footer;